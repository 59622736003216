import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Particle from "../Particle";
import emailjs from "emailjs-com";
import "./Contact.css";
import Calendly from "./Calendly";
import { FaInfoCircle } from "react-icons/fa";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_ug0nubt",
        "template_ehdkkgl",
        formData,
        "AmIovnCWa13mFr3Ze"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsSent(true);
        },
        (err) => {
          console.log("FAILED...", err);
          setError("Failed to send the message. Please try again.");
        }
      );
  };

  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={6}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Get in <strong className="purple">Touch</strong>
            </h1>

            {isSent ? (
              <p style={{ color: "green" }}>
                Message sent successfully! Thank you for reaching out. I look forward to connecting with you soon.
              </p>
            ) : (
              <>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Send Message
                  </Button>
                </Form>
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    marginTop: "50px",
                    textAlign: "center",
                  }}
                  className="purple"
                >
                  <FaInfoCircle style={{ marginRight: "5px" }} />
                  Please choose either to send a direct message or book a free consultation. I am truly excited to work with you and help you achieve your business needs.
                </div>
              </>
            )}

            {error && <p style={{ color: "red" }}>{error}</p>}
          </Col>
          <Col
            md={5}
            style={{
              paddingTop: "35px",
            }}
            className="contact-img"
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Book <strong className="purple">Free Consultation</strong>
            </h1>
            <Calendly />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Contact;
