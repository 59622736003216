import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import medaresha from "../../Assets/Projects/medaresha-ss.png";
import disruptorsDen from "../../Assets/Projects/tdd.png";
import invoiceApp from "../../Assets/Projects/invoice-app.png";
import rbac from "../../Assets/Projects/rbac.png";
import egp from "../../Assets/Projects/egp.png";
import bankosai from "../../Assets/Projects/bankos.png";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <ProjectCard
            imgPath={bankosai}
            isBlog={false}
            title="Bankos AI"
            description="Developed a web-based AI-powered web-application for banks to automate customer service, provide personalized recommendations, and improve customer engagement. To Streamline your loan processes with AI."
            demoLink="https://www.bankos.ai"
          />

          <ProjectCard
            imgPath={disruptorsDen}
            isBlog={false}
            title="Disruptors Den"
            description="Developed a website for an entrepreneurial community to foster networking and collaboration among innovators, with a focus on user-friendly design and efficient performance."
            demoLink="https://thedisruptorsden.com"
          />

          <ProjectCard
            imgPath={egp}
            isBlog={false}
            title="Electronic Government Procurement"
            description="Contributed to a web-based procurement system for government agencies to manage procurement processes, vendor relationships, and contract management."
            demoLink="https://production.egp.gov.et"
          />

          <ProjectCard
            imgPath={invoiceApp}
            isBlog={false}
            title="Invoice Management System"
            description="Developed a web-based invoice management system for small businesses to manage invoices, track payments, and generate financial reports."
          />

          <ProjectCard
            imgPath={medaresha}
            isBlog={false}
            title="Medaresha"
            description="A mobile app that helps users navigate, facilitate bookings & share experiences. Built the web platform to extend the mobile app’s functionality and improve user engagement."
            demoLink="https://medaresha.com"
          />

          <ProjectCard
            imgPath={rbac}
            isBlog={false}
            title="Role Based Access Management"
            description="Developed a web-based role-based access control system for organizations to manage user roles, permissions, and access control policies."
            className="dimmed"
          />
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
